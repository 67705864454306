<template>
    <div class="home">
        <div class="landing">
            <h1>Victoria Arduino<br><span>Ireland</span></h1>
            <div class="landing--arrow">
                <i class="arrow-down"></i>
            </div>
        </div>
        <div class="product-grid">
            <h2 class="product-grid--title">
                machines
            </h2>
            <div class="product-grid--list">
                <div class="product-grid--item" v-for="(product, index) in machines" :key="index" @click.prevent="showModal(product)">
                    <div class="product-grid--item-image">
                        <img :src="product.image">
                    </div>
                    <div class="product-grid--item-title">
                        {{ product.title }}
                    </div>
                </div>
            </div>
            <h2 class="product-grid--title">
                grinders
            </h2>
            <div class="product-grid--list">
                <div class="product-grid--item" v-for="(product, index) in grinders" :key="index" @click.prevent="showModal(product)">
                    <div class="product-grid--item-image">
                        <img :src="product.image">
                    </div>
                    <div class="product-grid--item-title">
                        {{ product.title }}
                    </div>
                </div>
            </div>
        </div>
        <transition name="fade" mode="out-in">
          <Modal @showModal="showModal" @closeModal="closeModal" :product="selectedProduct" v-if="isModalVisible"></Modal>
        </transition>
    </div>
</template>

<script>
// @ is an alias to /src
import Modal from '@/components/Modal.vue'

export default {
  name: 'Home',
  data: () => ({
    isModalVisible: false,
    selectedProduct: null,
    machines: [{
      title: 'Black Eagle',
      image: require('@/assets/images/black-eagle.jpg'),
      text: '<p>The espresso machine of choice for the World Barista Championship, the Black Eagle has become an icon amongst some of the best specialty coffee shops in the world. The Gravimetric option incorporates digital scales to provide an incredibly consistent and precise extraction every time.</p>'
    }, {
      title: 'Eagle One',
      image: require('@/assets/images/eagle-one.jpg'),
      text: '<p>The brainchild of coffee\'s James Hoffmann and architect Carlo Viglino, the Eagle on is a modern interpretation of the classic Victoria Arduino designs from the golden age of espresso. It\'s sustainable approach means a more efficient use of power with the same consistency in extraction. </p>'
    }, {
      title: 'White Eagle',
      image: require('@/assets/images/white-eagle.jpg'),
      text: '<p>The entry level workhorse from Victoria Ardunio, the White Eagle is the go-to for those looking for an entry level machine that can deliver high volumes, offer extraction consistency and still look great on the bar.</p>'
    }, {
      title: 'Eagle One Prima',
      image: require('@/assets/images/eagle-one-prima.jpg'),
      text: '<p>Perhaps the ultimate single group espresso machine, the Eagle One Prima is ideal for office, home or mid volume commercial use in restaurants and bars. Designed by Carlo Viglino, it is perhaps the most beautiful single group machine on the market and has the performance and consistency to back it up.</p>'
    }],
    grinders: [{
      title: 'Mythos 1',
      image: require('@/assets/images/m1.jpg'),
      text: '<p>Perhaps the world\'s favourite specialty coffee grinder, the consistency of dose and extraction make the mythos one the go-to for many of the worlds best baristas. Quiet, low profile and incredibly reliable it has become an essential part of any specialty coffee offering.</p>'
    }, {
      title: 'Mythos 2',
      image: require('@/assets/images/m2.jpg'),
      text: '<p>Replete with bigger burrs, a more advanced motor and even the option of a digital scale, the Mythos 2 is a grinder designed for high volume, high precision coffee shops. A digital interface and bluetooth capability mean its very literally at the cutting edge of coffee.</p>'
    }]
  }),
  components: {
    Modal
  },
  methods: {
    showModal (product) {
      this.isModalVisible = true
      this.selectedProduct = product
      document.querySelector('body').classList.add('modal--open')
    },
    closeModal () {
      this.isModalVisible = false
      document.querySelector('body').classList.remove('modal--open')
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
.landing {
    width: 100%;
    height: 100vh;
    background: #000000;
    position: relative;
    overflow: hidden;
    background: url(../assets/images/MMFC2629.jpg);
    background-size: cover;
    background-position: center center;
    @include breakpoint(tablet) {
        background-position: right 40% center;
    }
    h1 {
        font-weight: 800;
        color: #FFFFFF;
        font-size: 70px;
        position: absolute;
        top: 50%;
        left: 40px;
        transform: translate(0, -50%);
        line-height: 1;
        margin: 0;
        text-align: left;
        z-index: 1001;
        @include breakpoint(tablet) {
            font-size: 36px;
            left: 20px;
        }
        span {
            padding-left: 80px;
            @include breakpoint(tablet) {
                padding-left: 20px;
            }
        }
    }
    .landing--arrow {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 50px;
    }
}

.product-grid--list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    margin-bottom: 20vh;
    text-align: center;
    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
    }
}

.product-grid--title {
    width: 100%;
    text-align: center;
    font-size: 70px;
    line-height: 1;
    margin: 10vh 0;
    font-weight: 800;
    @include breakpoint(tablet) {
        font-size: 36px;
    }
}

.product-grid--item {
    position: relative;
    cursor: pointer;
    &:before {
        transition: all 0.6s ease-in-out;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1001;
        pointer-events: none;
        opacity: 0;
        transition-delay: 0.3;
        content: "MORE INFO";
        font-weight: 800;
        background: #000000;
        color: #FFFFFF;
        padding: 5px 10px;
        letter-spacing: 2px;
        transform: translate(-50%, -50%);
    }
    &:after {
        transition: all 0.6s ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        opacity: 0;
        content: "";
        background: rgba(255,255,255,0.8);
    }
    &:hover {
        &:after {
            opacity: 1;
        }
        &:before {
            opacity: 1;
        }
    }
}

.product-grid--item-title {
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: -10px;
}

.fade-enter-active, .fade-leave-active {
  transition: 0.6s all cubic-bezier(0,0,.13,.98);
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
</style>
