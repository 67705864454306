<template>
  <div id="app" :class="{ 'is--scrolled': isScrolled }">
    <header ref="header">
      <a href="/" class="logo"><img src="@/assets/images/arduino.svg"></a>
    </header>
    <router-view/>
    <footer>
      <div class="footer-grid">
        <div class="footer-grid--item">
          <h5>Sales</h5>
          <p><a href="mailto:sales@victoriaarduino.ie">sales@victoriaarduino.ie</a></p>
        </div>
        <div class="footer-grid--item">
        </div>
      </div>
      <div class="footer-copyright">Copyright {{ year }} © Victoria Arduino</div>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'App',
  data: () => ({
    isScrolled: false,
    year: null
  }),
  methods: {
    getYear () {
      var d = new Date()
      this.year = d.getFullYear()
    },
    scroll () {
      window.onscroll = () => {
        if (Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) > window.innerHeight) {
          this.isScrolled = true
        } else {
          this.isScrolled = false
        }
      }
    }
  },
  mounted () {
    this.getYear()
    this.scroll()
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800;900&display=swap');
@import "@/assets/scss/_reset.scss";

body, html {
  padding: 0;
  margin: 0;
}

body {
  font-size: 14px;
  line-height: 1.3;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
}

*, *:before, *:after {
  box-sizing: border-box;
}
a {
  color: #FFFFFF;
  text-decoration: none;
  outline: none;
}

p {
  margin-bottom: 20px;
  display: block;
}

img {
  max-width: 100%;
  width: 100%;
  display: block;
}

header {
  position: fixed;
  padding: 40px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1006;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  // background: #000000;
  @include breakpoint(tablet) {
      padding: 20px;
  }
  .logo {
    width: 240px;
    position: relative;
    display: block;
    transition: all 0.3s ease-in-out;
    pointer-events: auto;
    @include breakpoint(tablet) {
        width: 180px;
    }
  }
}

.is--scrolled {
  header {
    background: #000000;
    padding: 20px 40px;
    @include breakpoint(tablet) {
        padding: 20px;
    }
    .logo {
      width: 180px;
      @include breakpoint(tablet) {
          width: 140px;
      }
    }
  }
}

.modal--open {
  .is--scrolled {
    background: transparent;
  }
}

footer {
  background: #000000;
  color: #FFFFFF;
  padding: 40px;
  position: relative;
  @include breakpoint(tablet) {
      padding: 40px 20px;
  }
  .footer-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @include breakpoint(tablet) {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 20px;
    }
  }
  h5 {
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 2px;
    margin-bottom: 5px;
  }
  .footer-grid--item {
  }
  .footer-copyright {
    position: absolute;
    bottom: 20px;
    right: 40px;
    color: #666666;
    @include breakpoint(tablet) {
        grid-template-columns: repeat(2, 1fr);
        bottom: 15px;
        right: 20px;
        font-size: 12px;
    }
  }
}

.arrow-down {
  border: solid #FFFFFF;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
</style>
