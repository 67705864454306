<template>
    <div class="modal">
      <div class="modal--content">
        <div class="modal--title">{{ product.title }}</div>
        <div class="modal--text" v-html="product.text"></div>
        <div class="modal--image">
          <img :src="product.image">
        </div>
      </div>
      <button class="modal--close" @click.prevent="closeMenu()">Close</button>
      <div class="modal--header"></div>
      <div class="modal--backdrop"></div>
    </div>
</template>

<script>
export default {
  name: 'Modal',
  data: () => ({
  }),
  components: {
  },
  props: ['product'],
  mounted () {
  },
  computed: {
  },
  methods: {
    closeMenu () {
      this.$emit('closeModal')
    },
    showMenu () {
      this.$emit('showModal')
    }
  }
}
</script>

<style lang="scss">
.modal {
  z-index: 1002;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modal--backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1002;
  background: rgba(0,0,0,0.98);
}

.modal--header {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1004;
  background: #000000;
  height: 165px;
  @include breakpoint(tablet) {
    height: 104px;
  }
}

.is--scrolled {
  .modal--header {
    height: 104px;
    @include breakpoint(tablet) {
      height: 89px;
    }
  }
}

.modal--open {
  .is--scrolled header {
    background: transparent;
  }
}

.modal--content {
  position: relative;
  height: 100%;
  z-index: 1003;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  color: #FFFFFF;
  padding: 0 20px;
}

.modal--close {
  position: fixed;
  top: 40px;
  right: 40px;
  color: #FFFFFF;
  font-weight: 800;
  z-index: 1008;
  background: transparent;
  border: 0;
  font-size: 18px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
  @include breakpoint(tablet) {
    top: 20px;
    right: 20px;
  }
}

.modal--title {
  width: 100%;
  text-align: center;
  font-size: 70px;
  line-height: 1;
  margin: 200px 0 80px 0;
  font-weight: 800;
  @include breakpoint(tablet) {
      font-size: 36px;
  }
}

.modal--image {
  width: 800px;
  margin: 0 auto 80px auto;
  color: #FFFFFF;
  max-width: 100%;
}

.modal--text {
  width: 600px;
  margin: 0 auto 80px auto;
  color: #FFFFFF;
  text-align: center;
  max-width: 100%;
}

</style>
